import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Telefon from "../../assets/img/telefonLightBlue.svg";


const LinkButtonInner = styled.div`
  a {
    font-family: Visuelt-Medium;
    font-size: 18px;
    text-align: center;
    width: fit-content;
    max-width: 290px;
    margin: 0;
    display: block;
    background: none;
    border-radius: 5px;
    padding: 17px 22px;
    border: 2px solid #95A8C3;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 360px) {
      padding: 15px 0;
      max-width: none;
      width: calc(100% - 4px);
    }

    img {
      margin-right: 13px;
      position: relative;
      top: -1px;
      width: 22px;
      
      @media (max-width: 360px) {
        margin-right: 7px;
      }
    }

    span {
      font-family: Visuelt-Medium;
      color: #394B5C;
      font-size: 18px;

      @media (max-width: 360px) {
        font-size: 17px;
        white-space: nowrap;
      }
    }

    &:hover {
      background: #E2EBF8;
      cursor: pointer;
    }
  }
`;


const CallButtonV2 = ({ to, text, isBig }) => {
  return (
    <LinkButtonInner isBig={isBig}>
      <a href="tel:315558136">
        <img src={Telefon} alt="" />
        <span>Zavolejte na 315 558 136</span>
      </a>
    </LinkButtonInner>
  );
}

export default CallButtonV2;


