import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import GooglePic from "../../assets/img/googleFullRating.svg";
import FirmyPic from "../../assets/img/firmyFullRating.svg";

const RatingInner = styled.div`
  display: flex;
  align-items: stretch;

  .rating {
    p {
      font-family: Visuelt-Medium;
      font-size: 20px;
      color: #2c4668;
      margin-bottom: 15px;

      @media (max-width: 540px) {
        font-size: 18px;
      }
    }

    .pics {
      display: flex;
      align-items: center;

      @media (max-width: 540px) {
        display: block;
      }
      
      img {
        margin-right: 20px;
      }
    } 
  }

  .box {
    margin-right: 15px;
    width: 3px;
    border-radius: 3px;
    background: #44656D;
  }
`;


const RatingV2 = ({reviewDesc}) => {
  return (
    <RatingInner>
      <div className="box" />
      <div className="rating">
        <p>{reviewDesc}</p>
        <div className="pics">
          <img src={GooglePic} alt="Hodnocení pohřební služby Google" />
          <img src={FirmyPic} alt="Hodnocení pohřební služby Seznam" />
        </div>
      </div>
    </RatingInner>
  );
}

export default RatingV2;


